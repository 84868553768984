import React from 'react'
import { i18nTranslate } from 'src/utils'
import { withTranslation } from 'react-i18next'
import AOS from 'aos'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import {
  accountsContainer,
  customerContainer,
  studioContainer,
  storeContainer,
  homepageSchema,
} from 'src/models'
import {
  ApiContent,
  IS_BROWSER,
  getLocalStorage,
  getLocaleCodeFromUrl,
  getLiveEventStatus,
} from 'src/utils'
import { consentIpAddress } from 'config/appConfig'
import { EmailVerification, MetaDecorator } from 'src/views/components'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import {
  HeroBanner,
  HomePageTitleBanner,
  InfoBlock,
  Tagline,
  Separator,
  TabsSection,
  FeaturedinSection,
  CategorySection,
  AwardsSection,
  SuccessStoriesSection,
  NewsSection,
  BrandSection,
  SocialMedia,
  Features,
  Routines,
  Disclaimer,
  HighLighterMain,
} from 'src/views/studioComponents'
import LeaderPreviewModal from 'src/views/studioComponents/LeaderPreviewModal'
import { JsonLd } from 'src/views/components/JsonLd'
import { fontDetails, getCustomStyleForLocale } from './getDynamicLocaleFont'
import { EventStudioPage } from 'src/views/pages/EventStudioPage'
import './styles.scss'
import './custom_aos.scss'
const LAZYLOAD_START_INDEX = 3
@observer
class StudioPage extends React.Component {
  nameSpace = 'ssr-resource'
  static contextType = ApiContent
  @observable studioData = []

  constructor(props, context) {
    super(props)
    this.context = context
    this.contextName = 'studioData'
    this.getDataFromSource()
  }

  getDataFromSource = async () => {
    const studioData = this.context?.data?.studioData || {}

    if (Object.keys(studioData).length > 0) {
      // studioContainer.studioData = this.studioData
      this.studioData = studioData
    } else {
      await this.getStudioData()
    }
  }

  getStudioData = async () => {
    this.studioData = await studioContainer.getStudioResponse({
      context: this.context,
      contextName: this.contextName,
    })
    console.log(`getStudioData >>>> `, { res: this.studioData })
    studioContainer.studioData = this.studioData
  }

  renderPlaceholders = () => {
    return (
      <div className="position-relative w-100 d-lg-flex">
        <div className="col-12 col-lg-7 pr-0 d-sm-flex align-items-center float-left position-relative">
          <Skeleton rectangle={true} height={480} width={800} />
        </div>
        <div className="col-12 col-lg-5 p-0 d-md-flex flex-lg-column float-left">
          <div className="d-flex flex-column">
            <Skeleton rectangle={true} height={240} width={600} />
          </div>

          <div className="d-flex flex-column">
            <Skeleton rectangle={true} height={240} width={600} />
          </div>
        </div>
      </div>
    )
  }

  renderStudioWidgets = studioEntries => {
    // console.log({ studioEntries })

    const getHomePageComponentList = function () {
      return {
        hero: HeroBanner,
        home_page_title: HomePageTitleBanner,
        info_bar: InfoBlock,
        separator: Separator,
        tagline: Tagline,
        featured_products: TabsSection,
        featured_in: FeaturedinSection,
        showcase: Features,
        category_slider: CategorySection,
        awards: AwardsSection,
        influencers: Routines,
        brand_slider: BrandSection,
        highlight_block: HighLighterMain,
        success_slider: SuccessStoriesSection,
        news_section: NewsSection,
        social_media_slider: SocialMedia,
        disclaimer: Disclaimer,
      }
    }

    return (
      studioEntries.length > 0 &&
      studioEntries?.map((section, key) => {
        const componentName = Object.keys(section)[0]
        const DynamicComponent = getHomePageComponentList()[componentName]

        // console.log({ componentName, DynamicComponent })

        return (
          <React.Fragment key={`studio-hp-comps-${key}`}>
            {IS_BROWSER &&
              DynamicComponent &&
              (key > LAZYLOAD_START_INDEX &&
              !['hero', 'category_slider'].includes(componentName) ? (
                <LazyLoadComponent threshold={300}>
                  <DynamicComponent
                    data={section[componentName]}
                    {...this.props}
                  />
                </LazyLoadComponent>
              ) : (
                <DynamicComponent
                  data={section[componentName]}
                  {...this.props}
                />
              ))}
            {!IS_BROWSER &&
              DynamicComponent &&
              ['hero', 'category_slider'].includes(componentName) && (
                <DynamicComponent
                  data={section[componentName]}
                  {...this.props}
                />
              )}
          </React.Fragment>
        )
      })
    )
  }

  componentDidMount() {
    AOS.init({
      duration: 1500,
      once: true,
    })
  }

  getConsent = async (term1, term2) => {
    const { consents } = await customerContainer.signupConsents()
    const otherInfo = {
      browser: 'chrome',
      device: 'mobile',
      geoLocation:
        storeContainer.currentRegion || getLocalStorage('currentRegion') || '',
      ipAddress: consentIpAddress,
    }
    const signUpConsents = []

    consents.forEach(consent => {
      const Id = consent.id
      let consentInfo = ''

      if (
        term1 &&
        consent.title === 'I acknowledge I have read Sales performance'
      ) {
        consentInfo = {
          id: Id,
          consentId: Id,
          ...otherInfo,
          createdTimestamp: consent.createdTimestamp,
          updatedTimestamp: consent.updatedTimestamp,
        }
        signUpConsents.push(consentInfo)
      }
      if (
        term2 &&
        consent.title === 'I have Read, Understand, Agree to BA agreement'
      ) {
        consentInfo = {
          id: Id,
          consentId: Id,
          ...otherInfo,
          createdTimestamp: consent.createdTimestamp,
          updatedTimestamp: consent.updatedTimestamp,
        }
        signUpConsents.push(consentInfo)
      }
      if (consent.title === 'Terms & Conditions') {
        consentInfo = {
          id: Id,
          consentId: Id,
          ...otherInfo,
          createdTimestamp: consent.createdTimestamp,
          updatedTimestamp: consent.updatedTimestamp,
        }
        signUpConsents.push(consentInfo)
      }
    })

    const accType = getLocalStorage('accountType')
    const accountId = customerContainer.accountId
    const typeId = accountsContainer.profileResponse.typeId
    const name = accountsContainer.profileResponse.name

    const postData = {
      conversionType: 'TEMP_2_PERM',
      userConsent: [],
      account: {
        id: accountId,
        name: name,
        typeId: typeId,
        type: accType,
        addresses: [{}],
        customer: {},
      },
    }
    postData.userConsent = signUpConsents
    const loadParams = {
      endPointName: 'nextgenAccountUpgrade',
      pathParams: `${accountId}/upgrade`,
      postData,
    }

    const response = await customerContainer.fetchResponse(loadParams)
    if (customerContainer.isSuccessResponse(response)) {
    }
  }

  render() {
    const homePageTitle = i18nTranslate('homePage.title', 'Home | Nu Skin', {
      nameSpace: this.nameSpace,
    })
    /**
     * @note
     * NUSKIN-513 [CX121-246] | Console error is thrown on tapping the "Hi username" in header.
     * Commenting out the call, because of above mentioned mobx issue
     */
    // const triger = async () => {
    //   await accountsContainer.getProfile()
    // }
    // triger()

    let studioEntries = this.studioData?.entries?.[0]?.sections || []
    const schemaData = homepageSchema()
    let metaTitle =
      this.studioData?.entries?.[0]?.seo_information?.meta_title || ''
    let metaDesc =
      this.studioData?.entries?.[0]?.seo_information?.meta_description || ''
    const localeFromUrl = getLocaleCodeFromUrl()
    const enableLiveEvent = getLiveEventStatus()

    if (enableLiveEvent == 'true') {
      return <EventStudioPage studioEntries={studioEntries} />
    }

    return (
      <>
        {customerContainer.isRegisterUser &&
          localStorage?.getItem('userActivationKey') && <EmailVerification />}
        <MetaDecorator
          title={metaTitle}
          imageUrl=""
          imageAlt=""
          description={metaDesc}
          location={this.props}>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href={fontDetails[localeFromUrl]?.primaryFontUrl}
            rel="stylesheet"
          />
          <link
            href={fontDetails[localeFromUrl]?.secondaryFontUrl}
            rel="stylesheet"
          />
        </MetaDecorator>
        <JsonLd data={schemaData} key="schema-studio" />
        <div className="home-page" no-gutters="true">
          {studioEntries.length === 0 && this.renderPlaceholders()}
          {studioEntries.length > 0 && this.renderStudioWidgets(studioEntries)}
        </div>
        {<style>{getCustomStyleForLocale(localeFromUrl)}</style>}
        {customerContainer?.qualificationResponse?.isQualificationEligible ===
          true &&
          customerContainer?.qualificationStoreResponse === true && (
            <LeaderPreviewModal />
          )}
      </>
    )
  }
}

export { StudioPage }
export default withTranslation(['ssr-resource'])(StudioPage)
