import { deleteFromLocalStorage } from 'config/appConfig'
import React, { Component } from 'react'
import { customerContainer } from 'src/models'
import { i18nTranslate } from 'src/utils'
import { toastState } from '../Toast'

class EmailVerification extends Component {
  componentDidMount() {
    this.verifyUserEmail()
  }
  verifyUserEmail = async () => {
    const data = localStorage.getItem('userActivationKey')
    const response = await customerContainer.verifyUserEmail({
      activationParam: data,
    })
    deleteFromLocalStorage('userActivationKey')
    if (response.statusCode === 200) {
      toastState.setToastMessage(
        i18nTranslate(
          'verifyEmail.success',
          'Your email has been verified. Use it to log in next time.'
        ),
        true,
        5000
      )
    } else if (response.code === '1001') {
      const errorMessage = i18nTranslate(
        'verification.linkNotValid',
        "Verification link isn't valid"
      )
      toastState.setToastMessage(errorMessage, false, 5000)
    } else if (response.code === '1002') {
      const errorMessage = i18nTranslate(
        'verification.linkAlreadyVerified',
        'Link is already Verified'
      )
      toastState.setToastMessage(errorMessage, false, 5000)
    } else {
      const errorMessage =
        response.message ||
        i18nTranslate(
          'verifyEmail.failure',
          'Sorry, we are unable to verify your email address. Please try again.'
        )
      toastState.setToastMessage(errorMessage, false, 5000)
    }
  }
  render() {
    return <></>
  }
}

export { EmailVerification }
