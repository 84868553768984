import React from 'react'
import { getLocalStorage, setLocalStorage } from 'src/utils'
import { Modal } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { customerContainer } from 'src/models'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { QUALIFICATION_URL } from 'config/appConfig'
@observer
class LeaderPreviewModal extends React.Component {
  @observable showModal = true
  constructor(props) {
    super(props)
    this.currentLocale = getLocaleCodeFromUrl({
      locationProps: props,
      defaultLocale: 'en_US',
      isReverseType: true,
    })
  }
  handleClose = () => {
    setLocalStorage('closeManually', 'yes')
    this.showModal = !this.showModal
  }
  componentDidMount() {
    let closeManually = getLocalStorage('closeManually')
    if (closeManually) {
      this.showModal = false
    }
  }

  getQualificationUrl = () => {
    return QUALIFICATION_URL + this.currentLocale
  }

  render() {
    // let cookieValue = getLocalStorage('locale') || ''
    // let finalcookieValue = cookieValue.replace('-', '_')

    return (
      <>
        <Modal
          centered={true}
          size={'sm'}
          className={`modal-wrapper preview-modal p-0`}
          show={this.showModal}
          onHide={this.handleClose}
          dialogClassName="subsciprition-dialog">
          <Modal.Body>
            <Modal.Header closeButton className={'border-bottom-0'} />
            <div className="subsciprition-content">
              <p className="paragraph-m-bold pb-2">
                {customerContainer?.qualificationData?.early_access_modal_title}
              </p>
              <p className="paragraph-s pb-2">
                {
                  customerContainer?.qualificationData
                    ?.early_access_modal_message
                }
              </p>
              <a
                href={this.getQualificationUrl()}
                className="btn btn-primary border-0 w-100 text-uppercase leader-preview-modal-btn
                  rounded-0 d-flex justify-content-center align-items-center
                  align-content-center"
                //CX121-4490 - Open link in same tab
                // target="_blank"
                rel="noreferrer">
                {' '}
                {
                  customerContainer?.qualificationData
                    ?.early_access_modal_button
                }
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export { LeaderPreviewModal }
export default LeaderPreviewModal
