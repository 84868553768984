import './styles.scss'

let fontDetails = {
  vi_VN: {
    primaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap',
    primaryFontName: 'Source Serif Pro',
    secondaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap',
    secondaryFontName: 'Source Sans Pro',
  },
  ja_JP: {
    primaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap',
    primaryFontName: 'Noto Sans JP',
    secondaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap',
    secondaryFontName: 'Noto Sans JP',
  },
  en_VN: {
    primaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap',
    primaryFontName: 'Source Serif Pro',
    secondaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap',
    secondaryFontName: 'Source Sans Pro',
  },
  en_TH: {
    primaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Noto+Serif+Thai&display=swap',
    primaryFontName: 'Noto Serif Thai',
    secondaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap',
    secondaryFontName: 'Noto Sans Thai',
  },
  th_TH: {
    primaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Noto+Serif+Thai&display=swap',
    primaryFontName: 'Noto Serif Thai',
    secondaryFontUrl:
      'https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap',
    secondaryFontName: 'Noto Sans Thai',
  },
  default: {
    primaryFontName: 'SangBleuKingdom',
    secondaryFontName: 'Inter',
  },
}

const getCustomStyleForLocale = locale => {
  const selectedFont = fontDetails[locale] || fontDetails?.default

  return `
    body#${locale}{
      .hero-banner-left-inner h2, .hero-banner-right-inner h2, .category-head h3, 
      .separator-section .separator-container h3, .popular-sidebar .populat-text h3,
      .features-inner-text h3, .tagline-textcontainer .paragraph-l, .info-section .heading-1,
      .info-section .heading-1 span,
      .highlight-section .highlight-section-1 .highlight-section-content .heading-2,
      .highlight-section-2 .highlight-section-two-content .heading-2 {
        font-family: ${selectedFont?.primaryFontName} !important;
      }
      .hero-banner-section .hero-btn, .populat-text a span,
      .most-popular-slider .card-text .content, .most-popular-slider .pop-add-btn, .features-inner-text a,
      .highlight-section-content a span, .highlight-section-two-content a span, 
      .news-slider-view-all .news-view-all-btn h6, .newsslider .card-body .card-subtitle, .news-date,
      .hero-banner-left-inner h3,
      .hero-banner-right-inner h3, .hero-banner-right-inner button span, .homepage-title-heading h1,
      .category-section-slider-wrapper .viewmain .viewsec, .category-section-card .card-subtitle, .popular-section-inner .viewmain .viewsec,
      .separator-section .separator-container h4, .limit-title-height, .tabssection .prodtabs .nav-link, 
      .brandsec .viewmain .viewsec, .brandsection .head-3,
      .features-inner-text h6, .features-inner-text .paragraph-l, .tagline-title, .tagline-text2, 
      .awardsec .card-title.h5, .awardsec .award-text, .award-sub-text, .highlight-section-content p,
      .highlight-section-two-content p, .disclaimer-wrap span {
        font-family: ${selectedFont?.secondaryFontName} !important;
      }           
    } 
  `
}

export { fontDetails, getCustomStyleForLocale }
